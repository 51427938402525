<template>
    <div v-bind="$attrs" v-on:click="emit">
        <router-link
            v-if="link"
            :to="link"
            class="fold-bold sm:w-full lg:shadow-none hover:shadow-xl relative z-40 inline-block w-auto h-full px-5 py-3 text-sm font-bold leading-none text-white transition-all transition duration-100 duration-300 bg-indigo-600 rounded shadow-md"
        >
            <slot></slot>
        </router-link>
        <button
            :disabled="disabled"
            :class="{'opacity-75': disabled}"
            v-else
            class="fold-bold sm:w-full lg:shadow-none hover:shadow-xl relative z-40 inline-block w-auto h-full px-5 py-3 text-sm font-bold leading-none text-white transition-all transition duration-100 duration-300 bg-indigo-600 rounded shadow-md"
        >
            <div
                class="block relative"
            >
                <div :class="{'opacity-0':loading}">
                <slot></slot>
                </div>
                <svg
                    class="w-4 h-4 absolute top-0"
                    viewBox="0 0 38 38"
                    xmlns="http://www.w3.org/2000/svg"
                    v-if="loading"
                    stroke="currentColor"
                >
                    <g fill="none" fill-rule="evenodd">
                        <g transform="translate(1 1)" stroke-width="2">
                            <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 18 18"
                                    to="360 18 18"
                                    dur="1s"
                                    repeatCount="indefinite"
                                />
                            </path>
                        </g>
                    </g>
                </svg>
            </div>

        </button>
    </div>
</template>

<script>
export default {
    name: 'MainButton',
    props: {
        link: Object,
        disabled: Boolean,
        loading: Boolean
    },
    methods: {
        emit() {
            this.$emit('click')
        }
    }
};
</script>

<style scoped></style>
