<template>
    <div class="app flex flex-col min-h-screen bg-white">
        <div class="relative z-30 w-full shadow-sm">
            <div class="lg:w-7/12 w-full mx-auto">
                <navbar />
            </div>
        </div>
        <section class="sm:pt-24 z-10 flex flex-col w-full pt-8 mb-auto bg-white">
            <router-view></router-view>
        </section>
        <div
            class="md:hidden fixed bottom-0 z-30 flex flex-col w-full text-center"
            v-if="$store.state.ui.mobileWarningShown"
        >
            <MobileWarning />
        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Tooltip from './components/Tooltip';
import MainButton from './components/MainButton';
import MobileWarning from './components/MobileWarning';

export default {
    name: 'app',
    components: {
        MobileWarning,
        MainButton,
        Footer,
        Navbar,
        Tooltip,
    },
    data() {
        return {
            walletAddress: null,
            transitionName: '',
        };
    },
    methods: {
        async connectWallet() {
            await this.$store.dispatch('connectWallet');
        },
    },
};
</script>
