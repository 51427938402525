import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import {store} from '../store';

Vue.use(VueRouter);
const titlePrefix = 'TokenGen | ';
const defaultTitleSuffix = 'Create your own BEP20 Token for FREE';

const guides = [
    {
        path: '/guides/liquidity-with-pancakeswap',
        name: 'guides.liquidity',
        text: 'Liquidity',
        component: () => import('../views/guides/Liquidity.vue'),
        meta: {
            title: 'Liquidity with PancakeSwap',
        },
        data: {
            name: 'How to list your token on Pancakeswap',
            description: 'This guide will show you how to list your token on the PancakeSwap exchange. ' +
                'Other people will then be able to purchase your token.',
            imageSrc: 'https://i.imgur.com/0PC1LYd.png',
            pathName: 'guides.liquidity',
        },
    },
    {
        path: '/guides/burn',
        name: 'guides.burn',
        text: 'How to burn tokens',
        component: () => import('../views/guides/Burn.vue'),
        meta: {
            title: 'How to burn tokens',
        },
        data: {
            name: 'How to burn tokens',
            description: 'This guide will show you how to burn/destroy tokens from circulation and what ' +
                'benefits this can have for your token project.',
            imageSrc: 'https://images.prismic.io/swissborg-website/49eb04a9-f1b0-4502-9f0a-11393f630298_' +
                'TOKEN+BURNING+ARTICLE.png?auto=compress%2Cformat&fit=max&q=75',
            pathName: 'guides.burn',
        },
    },
    {
        path: '/guides/mint',
        name: 'guides.mint',
        text: 'How to mint tokens',
        component: () => import('../views/guides/Mint.vue'),
        meta: {
            title: 'How to mint tokens',
        },
        data: {
            name: 'How to mint tokens',
            description: 'This guide will show you how to mint/generate tokens into ' +
                'circulation and what benefits this can have for your token project.',
            imageSrc: 'https://i.imgur.com/a8LsGgd.png',
            pathName: 'guides.mint',
        },
    },
    {
        path: '/guides/verify-on-bscscan',
        name: 'guides.verify',
        text: 'How to verify your token',
        component: () => import('../views/guides/VerifyOnBscScan.vue'),
        meta: {
            title: 'How to verify your token',
        },
        data: {
            name: 'How to verify your token',
            description: 'This guide will show you how to verify your contract address on bscscan. ' +
                'Verified contracts gives you access to add/remove information about your token.',
            imageSrc: 'https://bscscan.com/images/brandassets/BscScan-logo.png',
            pathName: 'guides.verify',
        },
    },
    {
        path: '/guides/how-to-use-tokengen',
        name: 'guides.tokengen',
        text: 'How to use Tokengen',
        component: () => import('../views/guides/tokengen.vue'),
        meta: {
            title: 'How to use Tokengen',
        },
        data: {
            name: 'How to use Tokengen',
            description: 'This guide will go through the steps of creating and deploying a contract at Tokengen.',
            imageSrc: 'https://i.imgur.com/EPZaH35.png',
            pathName: 'guides.tokengen',
        },
    },
    {
        path: '/guides/metamask',
        name: 'guides.metamask',
        text: 'How to use setup MetaMask',
        component: () => import('../views/guides/MetaMask.vue'),
        meta: {
            title: 'How to setup MetaMask',
        },
        data: {
            name: 'How to setup MetaMask',
            description: 'This guide will go through the steps of installing and setting up ' +
                'MetaMask, as well as connecting to the Binance Smart Chain network.',
            imageSrc: 'https://www.ledger.com/wp-content/uploads/2019/06/assets_logo_metamask.jpg',
            pathName: 'guides.metamask',
        },
    },
    {
        path: '/guides/how-to-buy-bnb',
        name: 'guides.bnb',
        text: 'How to buy BNB',
        component: () => import('../views/guides/HowToBuyBnb.vue'),
        meta: {
            title: 'How to buy BNB',
        },
        data: {
            name: 'How to buy BNB',
            description: 'This guide will go through the steps of installing and setting up ' +
                'MetaMask, as well as connecting to the Binance Smart Chain network.',
            imageSrc: 'https://images.unsplash.com/photo-1614787296891-d1b2b1aced36?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
            pathName: 'guides.bnb',
        },
    },
];
const routes = [
    {
        path: '/',
        name: 'home',
        text: 'Home',
        inNav: true,
        component: Home,
        meta: {
            title: defaultTitleSuffix,
        },
    },
    {
        path: '/create-token',
        name: 'create',
        text: 'Create Token',
        inNav: false,
        component: () => import('../views/GeneratePage.vue'),
        meta: {
            title: 'Create token',
        },
    },
    {
        path: '/contact',
        name: 'contact',
        text: 'Contact',
        inNav: false,
        component: () => import('../views/Contact.vue'),
        meta: {
            title: 'Contact',
        },
    },
    {
        path: '/features',
        name: 'features',
        text: 'Features',
        inNav: true,
        component: () => import('../views/Features.vue'),
        meta: {
            title: 'Features',
        },
    },
    {
        path: '/guides',
        name: 'guides',
        text: 'Guides',
        inNav: true,
        component: () => import('../views/Guides.vue'),
        meta: {
            title: 'Guides',
        },
    },
    ...guides,
    {
        path: '/affiliate',
        name: 'affiliate',
        text: 'Affiliate',
        inNav: true,
        component: () => import('../views/Affiliate.vue'),
        meta: {
            title: 'Tokengen Affiliate Program',
        },
    },

    {
        path: '/faq',
        name: 'faq',
        text: 'FAQ',
        inNav: true,
        component: () => import('../views/FAQ.vue'),
        meta: {
            title: 'Frequently Asked Questions',
        },
    },
    {
        path: '/terms-of-service',
        name: 'tos',
        inNav: false,
        component: () => import('../views/tos.vue'),
        meta: {
            title: 'Terms of Service'
        }
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        inNav: false,
        component: () => import('../views/privacy-policy.vue'),
        meta: {
            title: 'Privacy Policy'
        }
    },
    {
        path: '/token-manager',
        name: 'token-manager',
        inNav: false,
        component: () => import('../views/TokenManager.vue'),
        meta: {
            title: 'Token Manager'
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({x: 0, y: 0})
            }, 0)
        })
    }

});

router.beforeEach((t, f, n) => {
    store.dispatch('ui/closeMenu');
    return n();
});

router.afterEach((t, f) => {
    Vue.nextTick(() => {
        if (t.meta && t.meta.title) {
            document.title = titlePrefix + t.meta.title;
        } else {
            document.title = titlePrefix + defaultTitleSuffix;
        }
    });
});

export {router, routes, guides};
