import Vue from 'vue';
import Vuex from 'vuex';

import ui from './ui';
import token from './token'
Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        ui,
        token
    },
    state: {
        walletConnected: false,
        walletAddress: null,
    },
    mutations: {
        setConnected(state, value) {
            state.walletConnected = value;
        },
        setAddress(state, { account }) {
            state.walletAddress = account;
        },
    },
    actions: {
        async connectWallet({ commit }) {
            try {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                const account = accounts[0]
                    .split(/(0x...).*(....)/)
                    .filter((m) => m)
                    .join('...');
                commit('setAddress', { account });
                commit('setConnected', true);
            } catch (e) {
                this._vm.$vToastify.warning({
                    title: 'Could not connect to wallet',
                });
            }
        },
    },
});

export { store };
