let verified = {
    text: "Verified contract",
    help: "Your contract will automatically be verified on BscScan.",
    cardText: "Verified contract",
    cardHelp: "Your contract will automatically be verified on BscScan.",
    shown: true,
    editable: false,
    value: true
}

let decimals = {
    text: "Decimals",
    editable: true,
    inputType: "number",
    help: "The amount of decimals the token has.",
    bind: "decimals",
    min: 0,
    max: 18,
    step: 1,
    cardText: "Decimals",
    cardHelp: "Choose the number of decimals to denote the lowest fraction of your token.",
    default: 9
}

let name = {
    shown: true,
    value: true,
    editable: true,
    text: "Token name",
    help: "The actual name of the token.",
    inputType: "string",
    cardText: "Custom token name",
    cardHelp: "Choose your own custom token name.",
    bind: "name"
}

let symbol = {
    shown: false,
    editable: true,
    text: "Token Symbol",
    help: "The symbol is typically between 3 and 5 letters long.",
    inputType: "string",
    cardText: "Custom token symbol",
    cardHelp: "Choose your own symbol to denote your token.",
    bind: "symbol"
}

let defaults = {
    fixed: {
        text: "Fixed supply",
        cardText: "Fixed supply",
        cardHelp: "Total supply will be created immediately. ",
        inputType: "number",
        help: "The total amount of tokens that will exist. These will be transferred into your wallet upon creation.",
        bind: "initialBalance",
        min: 1,
        max: 1e18
    },
    capped: {
        text: "Capped supply",
        cardText: "Capped Supply",
        cardHelp: "Sets a maximum supply that can ever exist of the token.",
        inputType: "number",
        help: "The maximum amount of tokens that can exist.",
        bind: "cap",
        min: 1,
        max: 1e18
    },
    transactionBurn: {
        text: "Auto burn",
        cardText: "Auto burn",
        cardHelp: "Automatically burns a percentage of each transaction. Deflating the tokens over time.",
        inputType: "number",
        help: "The percentage of each transaction that will automatically be burned.",
        extra: "%",
        bind: "transactionBurn",
        min: 0,
        max: 100,
        step: 0.1,
        default: 2
    },
    transactionSplit: {
        text: "Distribution fee",
        cardText: "Distribution fee",
        cardHelp: "Transfer a percentage of each transaction to every token-holder. The individual gain is bases on their current balance.",
        inputType: "number",
        help: "Percentage of transaction that will be split between holders. The individual gain is a function of the holders current balance.",
        extra: "%",
        bind: "transactionSplit",
        min: 0,
        max: 100,
        step: 0.1
    },
    initialSupply: {
        text: "Initial Supply",
        cardText: "",
        cardHelp: "",
        help: "The supply that will exist upon creation. If the cap is higher than this value you can mint them later.",
        inputText: "Initial Supply",
        inputType: "number",
        bind: "initialBalance",
        min: 1,
        max: 1e18
    },
    maxTransfer: {
        bind: "maxTransfer",
        cardText: "Set max transfer",
        cardHelp: "The max amount (in percent) that a single holder can transfer to another wallet in a single transaction.",
        text: "Set max transfer",
        inputType: "number",
        help: "The maximum percentage of the total supply that can be transferred in a single transaction.",
        min: 1,
        max: 100,
        step: 1,
        extra: "%"
    },
    mintable: {
        text: "Mintable",
        cardText: "Mintable",
        cardHelp: "Minting tokens means you'll be able to mint tokens into circulation.",
        editable: false,
        inputType: null,
    },
    burnable: {
        text: "Burnable",
        cardText: "Burnable",
        cardHelp: "Burnable tokens means you'll be able to burn(destroy) token from your circulation.",
        editable: false,
        inputType: null,
    },
    operable: {
        text: "Operable (EIP 1363)",
        cardText: "EIP 1363 compliant",
        cardHelp: "",
        editable: false,
        inputType: null
    },
    recoverable: {
        text: "Token recovery",
        cardText: "Token recovery",
        cardHelp: "Ability to recover tokens that mistakenly sent into the contract.",
        editable: false,
        inputType: null
    }

}

const defaultAttributesForBindings = {
    address: null,
    maxTransfer: null,
    initialBalance: null,
    cap: null,
    decimals: 9,
    transactionSplit: null,
    transactionBurn: null,
    symbol: null,
}

const list = [
    {
        name: "SimpleToken",
        type: "SimpleToken",
        arguments: ["name", "symbol", "decimals", "initialBalance", "commissionReceiver", "discountCode"],
        ...defaultAttributesForBindings,
        listed: true,
        canChange: true,
        description: "",
        highlighted: false,
        version: "2.0.0",
        standard: true,
        verified: true,
        detailed: true,
        fixed: true,
        capped: false,
        customizeDecimals: false,
        supplyType: "Fixed",
        accessType: "Ownable",
        mintable: false,
        burnable: false,
        operable: false,
        tokenRecover: false,
        removeCopyright: false,
        originalPrice: 0,
        price: 0.5,
        gas: 1333276,
        coming_soon: false,
        features: {
            verified,
            name,
            symbol,
            fixed: {
                value: true,
                shown: true,
                editable: true,
                default: 1000000000,
                locked: false,
                ...defaults.fixed
            },
            capped: {
                value: false,
                shown: false,
                editable: false,
                ...defaults.capped
            },
            mintable: {
                value: false,
                shown: false,
                ...defaults.mintable
            },
            burnable: {
                value: false,
                shown: false,
                ...defaults.burnable
            },
            operable: {
                value: false,
                shown: false,
                ...defaults.operable
            },
            recover: {
                value: false,
                shown: false,
                ...defaults.recoverable
            },
            transactionBurn: {
                value: false,
                shown: true,
                editable: false,
                ...defaults.transactionBurn
            },
            transactionSplit: {
                value: false,
                shown: true,
                editable: false,
                ...defaults.transactionSplit
            },
            decimals
        }
    },
    {
        name: "ProToken",
        type: "ProToken",
        arguments: ["name", "symbol", "decimals", "cap", "initialBalance", "commissionReceiver", "discountCode"],
        listed: false,
        canChange: true,
        description: "",
        highlighted: false,
        version: "2.0.0",
        standard: true,
        verified: true,
        detailed: true,
        fixed: false,
        capped: true,
        customizeDecimals: true,
        supplyType: "Unlimited",
        accessType: "Ownable",
        mintable: true,
        burnable: true,
        operable: true,
        tokenRecover: true,
        removeCopyright: true,
        originalPrice: 0,
        price: 2,
        gas: 2254686,
        coming_soon: false,
        ...defaultAttributesForBindings,
        features: {
            name,
            symbol,
            fixed: {
                value: false,
                shown: false,
                default: 21000000,
                editable: false,
                ...defaults.fixed
            },
            initialSupply: {
                value: true,
                shown: false,
                default: 100000000,
                editable: true,
                ...defaults.initialSupply
            },
            capped: {
                value: true,
                shown: true,
                default: 100000000,
                editable: true,
                ...defaults.capped
            },
            mintable: {
                value: true,
                shown: true,
                editable: false,
                ...defaults.mintable
            },
            burnable: {
                value: true,
                shown: true,
                editable: false,
                ...defaults.burnable
            },
            operable: {
                value: true,
                shown: true,
                editable: false,
                ...defaults.operable
            },
            recover: {
                value: true,
                shown: true,
                editable: false,
                ...defaults.recoverable
            },
            transactionBurn: {
                value: false,
                shown: true,
                editable: false,
                ...defaults.transactionBurn
            },
            transactionSplit: {
                value: false,
                shown: true,
                editable: false,
                ...defaults.transactionSplit
            },
            decimals
        }
    },
    {
        name: "DeflationaryToken",
        type: "DeflationaryToken",
        arguments: ["name", "symbol", "decimals", "initialBalance", "transactionSplit", "transactionBurn", "maxTransfer", "commissionReceiver", "discountCode"],
        ...defaultAttributesForBindings,
        listed: true,
        canChange: false,
        description: "",
        highlighted: true,
        version: "2.0.0",
        standard: true,
        verified: true,
        detailed: false,
        fixed: true,
        capped: false,
        customizeDecimals: false,
        transactionSplit: 0,
        transactionBurn: 0,
        supplyType: "Fixed",
        accessType: "Ownable",
        mintable: false,
        burnable: false,
        operable: false,
        tokenRecover: false,
        removeCopyright: false,
        originalPrice: 0,
        price: 0,
        gas: 3554686,
        coming_soon: false,
        specialForm: true,
        features: {
            name,
            symbol,
            fixed: {
                value: true,
                shown: true,
                default: 1000000000,
                editable: true,
                ...defaults.fixed
            },
            maxTransfer: {
                value: true,
                shown: true,
                default: 10,
                editable: true,
                ...defaults.maxTransfer
            },
            capped: {
                value: false,
                shown: false,
                editable: false,
                ...defaults.capped
            },
            mintable: {
                value: false,
                shown: false,
                ...defaults.mintable
            },
            burnable: {
                value: true,
                shown: true,
                editable: true,
                ...defaults.burnable
            },
            operable: {
                value: false,
                shown: false,
                editable: false,
                ...defaults.operable
            },
            recover: {
                value: false,
                shown: false,
                editable: false,
                ...defaults.recoverable
            },
            decimals,
            transactionSplit: {
                value: true,
                shown: true,
                editable: true,
                default: 2,
                ...defaults.transactionSplit
            },
            transactionBurn: {
                value: true,
                shown: true,
                editable: true,
                default: 2,
                ...defaults.transactionBurn
            }
        }
    },
    {
        name: "BasicToken",
        type: "BasicToken",
        arguments: ["name", "symbol", "decimals", "cap", "initialBalance", "commissionReceiver", "discountCode"],
        ...defaultAttributesForBindings,
        listed: true,
        canChange: true,
        description: "",
        highlighted: false,
        version: "2.0.0",
        standard: true,
        verified: true,
        detailed: true,
        fixed: false,
        capped: true,
        customizeDecimals: true,
        supplyType: "Fixed",
        accessType: "Ownable",
        mintable: true,
        burnable: true,
        operable: false,
        tokenRecover: false,
        removeCopyright: true,
        originalPrice: 0,
        price: 1,
        gas: 1708581,
        coming_soon: false,
        features: {
            name,
            symbol,
            fixed: {
                value: false,
                shown: false,
                default: 21000000,
                editable: false,
                ...defaults.fixed
            },
            initialSupply: {
                value: true,
                shown: false,
                default: 100000000,
                editable: true,
                ...defaults.initialSupply
            },
            capped: {
                value: true,
                shown: true,
                default: 100000000,
                editable: true,
                ...defaults.capped
            },
            mintable: {
                value: true,
                shown: true,
                ...defaults.mintable
            },
            burnable: {
                value: true,
                shown: true,
                ...defaults.burnable
            },
            operable: {
                value: false,
                shown: false,
                ...defaults.operable
            },
            recover: {
                value: false,
                shown: false,
                ...defaults.recoverable
            },
            transactionBurn: {
                value: false,
                shown: true,
                editable: false,
                ...defaults.transactionBurn
            },
            transactionSplit: {
                value: false,
                shown: true,
                editable: false,
                ...defaults.transactionSplit
            },
            decimals
        }
    },
    {
        name: "FreeToken",
        type: "FreeToken",
        arguments: ["name", "symbol", "decimals"],
        ...defaultAttributesForBindings,
        listed: false,
        canChange: false,
        description: "",
        highlighted: false,
        version: "2.0.0",
        standard: true,
        verified: true,
        detailed: false,
        fixed: true,
        capped: false,
        customizeDecimals: false,
        supplyType: "Fixed",
        accessType: "Ownable",
        mintable: false,
        burnable: false,
        operable: false,
        tokenRecover: false,
        removeCopyright: false,
        originalPrice: 0,
        price: 0,
        gas: 1083828,
        coming_soon: false,
        features: {
            name,
            symbol,
            fixed: {
                value: true,
                shown: true,
                default: 21000000,
                editable: true,
                locked: true,
                ...defaults.fixed
            },
            mintable: {
                value: false,
                shown: false,
                ...defaults.mintable
            },
            burnable: {
                value: false,
                shown: false,
                ...defaults.burnable
            },
            operable: {
                value: false,
                shown: false,
                ...defaults.operable
            },
            recover: {
                value: false,
                shown: false,
                ...defaults.recoverable
            },
            transactionBurn: {
                value: false,
                shown: false,
                editable: false,
                ...defaults.transactionBurn
            },
            transactionSplit: {
                value: false,
                shown: false,
                editable: false,
                ...defaults.transactionSplit
            },
            decimals
        }
    }
]

export default list
