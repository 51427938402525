<template>
    <div class="relative bg-white">
        <div class="sm:px-6 md:justify-start md:space-x-10 flex items-center justify-between px-4 py-6">
            <div class="flex justify-start">
                <router-link
                    :to="{ name: 'home' }"
                    class="relative flex items-center inline-block h-5 h-full font-black leading-none"
                >
                    <span class="ml-3 text-xl text-black">tokengen.</span>
                </router-link>
            </div>
            <div class="flex flex-grow"></div>
            <nav class="md:flex hidden space-x-10">
                <router-link
                    v-for="route in routes"
                    :key="route.name"
                    v-if="route.inNav"
                    :to="{ name: route.name }"
                    class="hover:text-indigo-500 text-base font-medium text-black"
                >
                    <span>{{ route.text }}</span>
                </router-link>
            </nav>
            <div class="flex flex-grow"></div>
            <div class="md:hidden -my-2 -mr-2" @click="openMenu">
                <button
                    type="button"
                    class="hover:text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md"
                    aria-expanded="false"
                >
                    <span class="sr-only">Open menu</span>
                    <svg
                        class="w-6 h-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>
                </button>
            </div>
            <div class="md:flex hidden space-x-4">
                <div @click="connectWallet" v-if="!$store.state.walletConnected">
                    <main-button>Connect wallet</main-button>
                </div>
                <div @click="connectWallet" v-if="$store.state.walletConnected">
                    <main-button>Connected: {{ $store.state.walletAddress }}</main-button>
                </div>
                <main-button :link="{ name: 'create' }">Create token</main-button>
            </div>
        </div>

        <div
            class="md:hidden absolute inset-x-0 top-0 z-50 p-2 transition origin-top-right transform"
            v-if="$store.state.ui.menuOpen"
        >
            <div class="ring-1 ring-black ring-opacity-5 divide-gray-50 bg-white divide-y-2 rounded-lg shadow-lg">
                <div class="px-5 pt-5 pb-6">
                    <div class="flex items-center justify-between">
                        <div class="flex justify-start">
                            <router-link
                                :to="{ name: 'home' }"
                                class="relative flex items-center inline-block h-5 h-full font-black leading-none"
                            >
                                <span class="ml-3 text-xl text-black">tokengen.</span>
                            </router-link>
                        </div>
                        <div class="-mr-2">
                            <button
                                type="button"
                                class="hover:text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md"
                                @click="closeMenu"
                            >
                                <span class="sr-only">Close menu</span>
                                <!-- Heroicon name: outline/x -->
                                <svg
                                    class="w-6 h-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="mt-6">
                        <nav class="gap-7 grid grid-cols-1">
                            <router-link
                                v-for="route in routes"
                                :key="route.name"
                                v-if="route.inNav"
                                :to="{ name: route.name }"
                                class="hover:bg-gray-50 flex items-center p-3 -m-3 rounded-lg"
                            >
                                <div class="ml-4 text-base font-medium text-gray-900">{{ route.text }}</div>
                            </router-link>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { routes } from '../router';
import MainButton from './MainButton';

export default {
    name: 'navbar',
    components: { MainButton },
    data() {
        return {
            routes,
            walletAddress: null,
            open: false,
        };
    },
    methods: {
        async connectWallet() {
            await this.$store.dispatch('connectWallet');
        },
        async openMenu() {
            await this.$store.dispatch('ui/openMenu');
        },
        async closeMenu() {
            await this.$store.dispatch('ui/closeMenu');
        },
    },
};
</script>
