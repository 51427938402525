<template>
    <footer aria-labelledby="footer-heading" class="bg-gray-900 mt-8">
        <h2 id="footer-heading" class="sr-only">Footer</h2>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="py-20 xl:grid xl:grid-cols-3 xl:gap-8">
                <div class="grid grid-cols-3 gap-8 xl:col-span-3">
                    <div class="space-y-12 md:space-y-0 md:grid md:grid-cols-2 md:gap-8">
                        <div>
                            <h3 class="text-sm font-medium text-white">
                                Company
                            </h3>
                            <ul role="list" class="mt-6 space-y-6">
                                <li class="text-sm">
                                    <router-link :to="{name: 'tos'}" class="text-gray-300 hover:text-white">
                                        Terms &amp; Conditions
                                    </router-link>
                                </li>

                                <li class="text-sm">
                                    <router-link :to="{name: 'privacy-policy'}" class="text-gray-300 hover:text-white">
                                        Privacy Policy
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="space-y-12 md:space-y-0 md:grid md:grid-cols-2 md:gap-8">

                        <div>
                            <h3 class="text-sm font-medium text-white">
                                Connect
                            </h3>
                            <ul role="list" class="mt-6 space-y-6">
                                <li class="text-sm text-gray-300 hover:text-white">
                                    <a href="mailto:contact@tokengen.net" class="">
                                        contact@tokengen.net
                                    </a>
                                </li>

                                <li class="text-sm">
                                    <a href="https://twitter.com/tokengennet" target="_blank" rel="noopener"
                                       class="text-gray-300 hover:text-white">
                                        Twitter
                                    </a>
                                </li>
                                <li class="text-sm">
                                    <a href="https://facebook.com/tokengennet" target="_blank" rel="noopener"
                                       class="text-gray-300 hover:text-white">
                                        Facebook
                                    </a>
                                </li>

                                <li class="text-sm">
                                    <a href="https://www.instagram.com/tokengennet/" target="_blank" rel="noopener" class="text-gray-300 hover:text-white">
                                        Instagram
                                    </a>
                                </li>

                                <li class="text-sm">
                                    <a href="https://t.me/tokengennet" target="_blank" rel="noopener"
                                       class="text-gray-300 hover:text-white">
                                        Telegram
                                    </a>
                                </li>

                                <li class="text-sm">
                                    <a href="https://discord.gg/9jZVBGZghr" target="_blank" rel="noopener" class="text-gray-300 hover:text-white">
                                        Discord
                                    </a>
                                </li>
                                <li class="text-sm">
                                    <a href="https://reddit.com/r/tokengen" target="_blank" rel="noopener" class="text-gray-300 hover:text-white">
                                        Reddit
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="space-y-12 md:space-y-0 md:grid md:grid-cols-2 md:gap-8">

                        <div>
                            <h3 class="text-sm font-medium text-white">
                                Affiliate and Promotion
                            </h3>
                            <ul role="list" class="mt-6 space-y-6">
                                <li class="text-sm">
                                    <router-link :to="{name: 'affiliate'}" class="text-gray-300 hover:text-white">
                                        Affiliate Program
                                    </router-link>
                                </li>


                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="border-t border-gray-800 py-10">
                <p class="text-sm text-gray-400">Copyright &copy;
                    2021{{ new Date().getFullYear() !== 2021 ? ' - ' + new Date().getFullYear() : '' }} Tokengen</p>
            </div>
        </div>
    </footer>
</template>

<script>
import SocialList from './SocialList';

export default {
    name: 'Footer',
    components: {
        SocialList,
    },
};
</script>

<style scoped></style>
