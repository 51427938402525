export default {
    namespaced: true,
    state: {
        menuOpen: false,
        mobileWarningShown: true,
    },
    mutations: {
        setMenu: (state, menuState) => (state.menuOpen = menuState),
        setMobileWarning: (state, warningState) => (state.mobileWarningShown = warningState),
    },
    actions: {
        toggleMenu: ({ commit, state }) => commit('setMenu', !state.menuOpen),
        openMenu: ({ commit }) => commit('setMenu', true),
        closeMenu: ({ commit }) => commit('setMenu', false),
        hideMobileWarning: ({ commit }) => commit('setMobileWarning', false),
    },
};
