<template>
    <div class="z-50 flex flex-row items-center p-5 text-white bg-indigo-500">
        <div class="flex justify-center flex-grow">To use Tokengen.net you'll need to use a non-mobile device.</div>
        <div
            class="flex flex-shrink p-3 bg-indigo-400 border border-indigo-300 rounded cursor-pointer"
            @click="$store.dispatch('ui/hideMobileWarning')"
        >
            &cross;
        </div>
    </div>
</template>

<script>
export default {
    name: 'MobileWarning',
};
</script>

<style scoped></style>
