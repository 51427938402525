import Vue from 'vue';
import VueToastify from 'vue-toastify';
import web3 from 'web3';
import VueAnalytics from 'vue-analytics';
import { store } from './store';
import settings from './settings.json';
import tokens from './tokens.js';
import ClickOutside from 'vue-click-outside';
import Popover from 'vue-js-popover'


import App from './App.vue';

import { router } from './router';
import './assets/tailwind.css';

Vue.use(VueAnalytics, {
    id: 'UA-196655489-1',
});

Vue.use(VueToastify, {
    position: 'bottom-right',
});
Vue.use(Popover, { tooltip: true })
Vue.mixin({
    data: function () {
        return {
            settings,
            tokens,
        };
    },
});

Vue.filter('short', (v) => {
    if (v < 1e3) return v || 0;
    if (v >= 1e3 && v < 1e6) return +(v / 1e3).toFixed(1) + 'K';
    if (v >= 1e6 && v < 1e9) return +(v / 1e6).toFixed(1) + 'M';
    if (v >= 1e9 && v < 1e12) return +(v / 1e9).toFixed(1) + 'B';
    if (v >= 1e12 && v < 1e15) return +(v / 1e12).toFixed(1) + 'T';
    if (v >= 1e15) return +(v / 1e15).toFixed(1) + 'Q';
})

Vue.filter('numberFormat', v => new Intl.NumberFormat().format(v))

Vue.directive('click-outside', ClickOutside);

window.Web3 = web3;
Vue.config.productionTip = false;
new Vue({
    store,
    router,
    render: (h) => h(App),
}).$mount('#app');
