export default {
    namespaced: true,
    state: {
        name: '',
        symbol: '',
        decimals: null,
        cap: null,
        initialBalance: null,
        transactionSplit: null,
        transactionBurn: null,
    },
    mutations: {
        setName: (state, value) => state.name = value,
        setSymbol: (state, value) => state.symbol = value,
        setDecimals: (state, value) => state.decimals = value,
        setCap: (state, value) => state.cap = value,
        setInitialBalance: (state, value) => state.initialBalance = value,
        setTransactionSplit: (state, value) => state.transactionSplit = value,
        setTransactionBurn: (state, value) => state.transactionBurn = value,
    },
    actions: {
        update: ({commit}, data) => {
            commit('setName', data.name)
            commit('setSymbol', data.symbol)
            commit('setDecimals', data.decimals)
            commit('setCap', data.cap)
            commit('setInitialBalance', data.initialBalance)
            commit('setTransactionSplit', data.transactionSplit)
            commit('setTransactionBurn', data.transactionBurn)
        }
    }
}
