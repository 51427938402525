<template>
    <div class="flex flex-wrap">
        <div class="w-full text-center">
            <div ref="btnRef" v-on:mouseenter="toggleTooltip()" v-on:mouseleave="toggleTooltip()">
                <slot name="activator" />
            </div>
            <div
                ref="tooltipRef"
                :class="{ hidden: !tooltipShow, block: tooltipShow }"
                class="z-50 block max-w-xs ml-3 text-sm font-normal leading-normal text-left text-gray-800 no-underline break-words bg-gray-200 border-0 rounded-lg"
            >
                <div>
                    <div
                        class="p-3 mb-0 font-semibold uppercase bg-gray-200 border-b border-gray-100 border-solid rounded-t-lg opacity-75"
                    >
                        <slot name="title" />
                    </div>
                    <div class="p-3">
                        <slot name="content" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

export default {
    name: 'Tooltip',
    data() {
        return {
            tooltipShow: false,
        };
    },
    methods: {
        toggleTooltip: function () {
            if (this.tooltipShow) {
                this.tooltipShow = false;
            } else {
                this.tooltipShow = true;
                createPopper(this.$refs.btnRef, this.$refs.tooltipRef, {
                    placement: 'right',
                });
            }
        },
    },
};
</script>
